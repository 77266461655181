
html {
	height:100%;
	font-size:87.5%;


	@include breakpoint($tablet){
		font-size:87.5%;
	}
	@include breakpoint($desktop){
		font-size:100%;
	}
	@include breakpoint($fullhd){
		font-size:112.5%;
	}
}

body {
	font-family:$font-main;
	color:$black;
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	font-weight:300;
  // padding-top:rem-calc(80px);
  padding-top:rem-calc(78px);
  @include breakpoint($tablet){
    padding-top:rem-calc(91px);

    // futura
    // padding-top:rem-calc(93px);
  }
	@include breakpoint(1025px){
    // futura
    // padding-top:rem-calc(105px);

    // texg
  }
  @include breakpoint($fullhd){
    padding-top:rem-calc(102px);
  }
}

img {
	max-width:100%;
}

hr {
  border:1px solid #eee;
  border-width:1px 0 0 0;
  margin:0;
}