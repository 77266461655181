/* Susy */

$container-size: 1400px;
$total-columns: 12;

$susy: (
	container: $container-size,
	columns: $total-columns,
	gutters: 1/3,
	global-box-sizing: border-box,
	// debug: (
	// 	image: show,
	// 	color: rgba(#66f, .25),
	// 	output: background,
	// 	toggle: top right,
	// )
);

@include border-box-sizing;