.is-clearfix {
  @include clearfix;
}

.is-pulled-left {
  float: left !important;
}

.is-pulled-right {
  float: right !important;
}

// Overflow

.is-clipped {
  overflow: hidden !important;
}

.no-buffer {
  padding-bottom:0;
  .content-wrap {
    padding-bottom:0;
  }
}