.accordion {
	border-bottom:1px solid $grey-light;
	margin:0 0 rhythm(2) 0;
}

.accordion-trigger,
.accordion-download {

	@include clear-button-styles;
	background-color: transparent;
	color:$black;
	border-top:1px solid $grey-light;
	width:100%;
	text-align:left;
	padding: 0;
	margin: 0;
	cursor:pointer;
	transition:all .3s;
  display: block;
  outline:none;
  &:focus {
    outline:none;
    @include breakpoint($tablet){
      background-color: #eee;
    }
  }

	&[aria-expanded="true"]{
		.accordion-icon {
			&::after {
				transform:translate(-50%,-50%) rotate(0deg);
			}
		}
	}

	&[aria-expanded="true"],
	&:focus {
		// background-color: $grey-light;
	}

}



.accordion-title {
	pointer-events: none;
	outline: none;
	flex-grow:1;

  @include adjust-font-size-to(18px);
  @include adjust-leading-to(2,18px);
  padding:0;
  @include breakpoint($tablet){
    @include adjust-font-size-to($font-m);
    padding:0 0 0 1.8rem;
    @include adjust-leading-to(2,$font-m);
  }
  color:$blue;
	display: flex;
  justify-content:space-between;
  @include small-uppercase;
	@include breakpoint($tablet){
		padding:0 0 0 gutter(6);
	}
}

.accordion-icon {
	background-color: transparent;
	display: block;
	width:rhythm(2);
	height:rhythm(2);
	padding: 0;
	position:relative;
	// border-left:1px solid $grey;
	&:after {
		content:"";
		display: block;
		@include caret(10px,$black);
		position:absolute;
		left:50%;
		top:50%;
		transition:transform .3s;
		transform:translate(-50%,-50%) rotate(-180deg);
	}
}

.accordion-download {
	text-decoration:none;
	.accordion-icon {
		text-align:center;
		line-height:rhythm(2);
		&:after {
			display: none;
		}
		.fa {}
	}
}

.accordion-panel {

}

.accordion-content {
	// border-top:1px solid $grey;

	padding:1.8rem 0;
	@include breakpoint($tablet){

		padding:rhythm() 0 rhythm(2) gutter(6);
	}

  @include body-styles;

  p {
    @include adjust-font-size-to(18px);
    @include breakpoint($tablet){
      @include adjust-font-size-to(24px);
    }
  }

}



.accordion-content {
	p,
	ul,
	ol {
		a {
			text-decoration:none;
			color:$black;
			transition:color .3s;
			&:hover,
			&:focus {
				text-decoration:underline;
				color:$blue;
			}
		}
	}
}

.accordion-content--group {
	margin-bottom:rhythm(2);
	@include last-item;
}