.module-tiles {


	.row {

	}

}

.tiles-header,
.tiles,
.tiles-footer {

	@include breakpoint($tablet){
		@include span(10);
		@include pre(1);
	}
}

.tile {

}

@supports( display:grid ){

  .tiles {
    display:grid;
    grid-gap:rhythm();
    justify-content: center;
    @include breakpoint($tablet){

      grid-template-columns:repeat(3,1fr);
    }
  }

}

a.tile {

  text-decoration:none;

	img {
		display: block;
		z-index:1;
	}
	.tile-image {
		display: block;
		position: relative;
		&:after {
			content:"";
			display: block;
			width:100%;
			height:100%;
			position:absolute;
			z-index:1;
			background-color: $blue;
			opacity:0;
			transition:opacity .3s;
			top:0;
			left:0;
		}
		&:hover,
		&:focus {
			&:after {
				opacity: 0.7;
			}
		}
	}

}

.tile-title {
  h3 {
    text-align:center;
    color:$blue;
    font-family:$font-head;
    @include fs($font-l);
    @include breakpoint($tablet){
      @include fs($font-xl);

    }
  }
}

.tiles-default {
	.tile {
		@include breakpoint($tablet){
			&:nth-child(4n+4){
				@include pre(1 of 10);
			}

			&:nth-child(6n+6){
				@include last;
			}
		}
	}
}

.tiles-flex {
	.tiles {
		@include breakpoint($tablet){
			display:flex;
			flex-wrap:wrap;
			justify-content: space-between;
			@include span(9 last);
			@include post(1);
			@include pre(0);
		}
		.tile {
			@include breakpoint($tablet){
				float:none;
				margin:0;
				width:span(3 of 9);
				margin-bottom:rhythm(2);
			}
		}
	}
}

.tile-title {
	display: block;
	// @include fs($font-m);
	line-height:1.2;
	// font-family: $font-head;
	margin:0;
	margin-top:1rem;
}

.tile-image {
  margin:0 0 rhythm() 0;
  -webkit-clip-path: circle(50% at 50% 50%);
  clip-path: circle(50% at 50% 50%);
  display: inline-block !important;
  @include breakpoint($tablet){

  }
}

.tile-body {
	margin-top:rhythm(.5);
}

.tiles-for-content {
	.tile-title {}
}

.tiles-footer {
	@include breakpoint($tablet){
		display:flex;
		justify-content: flex-end;
	}
	a {
		// @include fs($font-l);
		margin-bottom:0;
	}
}