.module-cta {
  position: relative;

  .hero-body {
    flex-direction:column;
    text-align:center;

    .title {
      h2 {
        font-weight:600;
        line-height:1.5em;
      }
      margin-bottom:rhythm();
    }

    .title,
    .subtitle {
      color:$white;
    }
  }

  .subtitle {
    @include fluid-type(375px,1200px,$font-base,30px);
  }

  // .hero-bg {
  //   height:auto;

  //   img {
  //     height:auto;
  //     position:static;
  //     display: block;
  //   }
  // }



}

.module-cta {
  height:auto;
  .hero-body {
    position:relative;
    padding-top:rhythm(2);
    padding-bottom:rhythm(2);
    @include breakpoint($tablet){
      padding-top:0;
      padding-bottom:0;
      position:absolute;
    }
  }
  .hero-bg {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    @include breakpoint($tablet){
      position:relative;
      height:auto;
    }
  }
}

.module-cta .hero-bg {
  @include breakpoint($tablet){
    height:500px;
  }
}

.hero-footer {
  @include breakpoint($tablet){
    margin-top:rhythm(3);
  }
  // p {
  //   @include fs($font-m);
  // }
}