/* Colours */

$black: #36383b !default;
$black-bis: hsl(0, 0%, 7%) !default;
$black-ter: hsl(0, 0%, 14%) !default;

$grey-darker: hsl(0, 0%, 21%) !default;
$grey-dark: hsl(0, 0%, 29%) !default;
$grey: #4b4c51 !default;
$grey-light: #e6eef0 !default;
$grey-lighter: hsl(0, 0%, 86%) !default;

$white-ter: hsl(0, 0%, 96%) !default;
$white-bis: hsl(0, 0%, 98%) !default;
$white: #fff !default;

$orange: #FF4F03 !default;
$yellow: #FEA500 !default;
$green: #265C42 !default;
$turquoise: hsl(171, 100%, 41%) !default;
$cyan: hsl(204, 86%, 53%) !default;
$blue-dark: #00435d !default;
$blue: #0099D8 !default;
$blue-light: #7cd3ff;
$purple: hsl(271, 100%, 71%) !default;
$red: hsl(348, 100%, 61%) !default;

$primary: $blue !default;

$light: $white-ter !default;
$dark: $grey-darker !default;

// Invert colors

$colors : (
	"black" : $black,
	"white" : $white
);