.module-hero {

  .row {
    width:100%;
  }

  position:relative;

  .module-inner {
    max-width:1500px;
    margin:0 auto;
  }

}

.hero-bg {

  display: block;
  height:320px;
  @include breakpoint($tablet){
    height:500px;
  }
  position:relative;

  img {
    width:100%;
    height:100%;
    object-fit: cover;
    font-family: 'object-fit: cover;';
    position:absolute;
    z-index:2;
  }

}

.wrap-default {
  .hero-bg {
    height:320px;
    @include breakpoint($tablet){
      height:500px;
    }
  }
}

.wrap-thin {
  .hero-bg {
    height:180px;
    @include breakpoint($tablet){
      height:240px;
    }
  }
}

.wrap-full {
  .hero-bg {
    height:auto;
    img {
      position: static;
      height:auto;
      display: block;
    }
    @include breakpoint($tablet){
      height:auto;
    }
  }
}

.hero-flex {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  .hero-body {
    display: block;
  }
}

.hero-body {
  display: flex;
  justify-content: center;
  align-items:center;
  position: absolute;
  top:0;
  left:0;
  width: 100%;
  height:100%;
  background-color: rgba(56,56,56,.3);
  z-index:201;
  padding:0 rhythm(2);

  .title {
    // @include fs($font-xxl);

    margin:0;
    font-weight:600;
    h1 {
      color:$white;
      font-weight:600;
      text-align:center;
    }
  }

  .hero-body--content {
    @include body-styles;
    * {
      color:$white;
    }
    p {
      &:first-of-type {
        @include fs($font-m * 1.2);
      }
      a {
        color:$white !important;
        &:hover,
        &:focus {
          text-decoration:none;
        }
      }
    }
  }


}

.tint-dark {

}

.hero-body .title {
  @include fluid-type(320px,1200px,24px,72px);
}

// .is-fixed-height

.arrow-down {
  cursor:pointer;
  text-align:center;


  &:after {
    @include has-icon('(');
  }


  width:rhythm(2);
  position:absolute;
  left:50%;
  z-index:5;
  transform:translateX(-50%);
  @include animation(pulseDown, 1s, $timing-function: linear, $iteration-count: infinite, $direction: alternate);
  @include fs(90px);
  margin: 0;
  bottom:10%;
  // display: none; // not on mobile
  @include breakpoint( $phoneLandscape ){
    @include fs(24px);
    margin-bottom: 0;
    bottom:5%;
  }
  @include breakpoint($tablet){
    display: block;
    @include fs(48px);
    margin-bottom: 0;
    bottom:8%;
  }
  @include breakpoint(1025px){
    @include fs($font-xl);
    margin-bottom: 0;
    bottom:7%;
  }

}

.bg-orange,
.bg-yellow {
  .arrow-down {
    color:$black;
  }
}

.bg-green {
  .hero-body .title h1 {
    color:$white !important;
  }
  .arrow-down {
    color:$white;
  }
}

.bg-black,
.bg-green {
  &.hero-no-image {
    @include background-2x('../img/header-blank','png',no-repeat, false);
  }
}

.bg-white,
.bg-yellow,
.bg-orange {
  &.hero-no-image {
    @include background-2x('../img/header-blank2','png',no-repeat, false);
  }
}