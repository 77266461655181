@include headings;
@include paragraphs;

@include load-font('Texgy','texgyreadventor-regular-webfont',normal,normal);
@include load-font('Texgy Bold','texgyreadventor-bold-webfont',normal,bold);
@include load-font('Texgy Italic','texgyreadventor-italic-webfont',italic,normal);

.title {
	@include fs($font-xl-s);
	@include breakpoint($tablet){
		@include fs($font-xl);
	}
	font-family:$font-condensed;
	letter-spacing:-.01em;
}

.subtitle,
.listings-header {
	@include fs($font-xl-s);
	@include breakpoint($tablet){
		@include fs($font-xl);
	}
	font-family:$font-condensed;
}

.content-block,
.article-content {

	/* set in mixins */
	@include body-styles;

}