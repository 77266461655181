/*
Settings
*************************************************************************************/

$banner-bg-colour 			: $grey;
$banner-height-mobile 		: 320px;
$banner-height-tablet 		: 400px;
$banner-height-desktop		: 500px;



.module-banner,
.banner {
	position:relative;
	height:$banner-height-mobile;
	background-color: $banner-bg-colour;
	padding:0;
	@include breakpoint($tablet){
		height:$banner-height-tablet;
	}
	@include breakpoint($desktop){
		height:$banner-height-desktop;
	}
}

.banner-no-bleed {
	&.module-banner,
	.banner {
		height:auto !important;
	}
	.banner-slide {
		img {
			display: block;
			height:auto;
		}
	}
}

.banner-preloader {
	position:absolute;
	left:-999em;
	width:0px;
	height:0px;
}

.banner {
	opacity:0;
	visibility: hidden;
	width:100%;
	position:relative;
	transition:opacity 1000ms;
	&.is-active {
		opacity:1;
		visibility: visible;
	}
}

.banner-slide {
	position:relative;
	display:block;
	width:100%;
	height:100%;

	img {
		width:100%;
		height:100%;
		object-fit:cover;
		font-family: 'object-fit: cover;'
	}
}

.banner-slide--desktop {
	display: none;
	@include breakpoint($tablet){
		display: block;
		height:100%;
	}
}

.banner-slide--mobile {
	display: block;
	height:100%;
	@include breakpoint($tablet){
		display: none;
	}
}

.banner-body {
	position:absolute;
	top:0;
	left:0;
  width:100%;
  height:100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  background-color: rgba(56,56,56,.2);

	@include breakpoint($desktop){

  }

  .title {
    color:$white;
    font-weight:bold;
  }

	.subtitle {
    color:$white;
		margin-bottom:0;
  }

}

.banner-body .title {
  @include fluid-type(375px,1200px,30px,$font-xxl);
}

.banner-body .subtitle {
  @include fluid-type(375px,1200px,$font-base,30px);
}

.banner-loader {
	@include loader($white);

	position:absolute;
	z-index:201;
	left:50%;
	top:50%;
	margin-left:-1em;
	margin-top:-1em;
}

.banner-body,
.banner-pager,
.banner-next,
.banner-prev {
	z-index:201;
}

.banner-pager {
	position:absolute;
	left:25%;
	bottom:rhythm(1);
	@include breakpoint($desktop){
		bottom:rhythm(2);
	}

	width:50%;
	line-height:10px;
	@include breakpoint($desktop){
		line-height:15px;
	}
	span {
		display: inline-block;
		margin:0 rhythm(0.5) 0 0;
		width:10px;
		height:10px;
		border-radius:10px;
		@include breakpoint($desktop){
			width:15px;
			height:15px;
			border-radius:15px;
		}
		background-color:$white;
		cursor:pointer;
		text-indent:-999em;
		transition:color .3;
		&.cycle-pager-active {
			background-color:$primary;
		}
	}
}

.banner-pager-center {
	text-align:center;
	span {
		margin:0 rhythm(.25);
	}
}

.banner-next, .banner-prev {

	width:rhythm();
	height:rhythm();
	-webkit-transform-origin:50% 50%;
	transform-origin:50% 50%;
	transform:rotate(-45deg);
	position:absolute;
	display:block;
	transition:all 250ms $easing;
	top:50%;
	border-top:0;
	border-left:0;
	border-bottom:4px solid $white;
	border-right:4px solid $white;
	right:rhythm();
	margin-top:rhythm(-0.5);
	cursor:pointer;
  transition:all 250ms $easing;
  outline:none;
  // &:focus {
  //   outline:thin dotted;
  // }

}

.banner-prev {
	right:auto;
	left:rhythm();
	border-top:4px solid $white;
	border-left:4px solid $white;
	border-bottom:0;
	border-right:0;
}
