.wrap {
	box-sizing: content-box;
	padding:0 gutter();
	@include clearfix;
}

.row {
	padding:0 2rem;
	@include breakpoint(1025px){
		padding:0 gutter();
	}
	@include container;

	&.is-flex {
		display:flex;
		justify-content:space-between;
	}

	// &.no-flex {
	// 	@include container;
	// }
}