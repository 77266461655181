.module-signup {
  background-color: shade($blue,25%);
  color:$white;
  padding:rhythm(3) 0;

  .is-flex {
    justify-content: center;
  }
}

.signup {
  h3 {
    @include fs($font-m);
    font-weight:bold;
    text-align: center;
  }
}

.signup-form {
  display: inline-flex;
  label {
    position: absolute;
    z-index:-999;
  }
}

.signup-input {
  border:0;
  // @include adjust-font-size-to($font-m);
  padding:1rem;
  min-width:rem-calc(240px);
}

.signup-button {
  @include clear-button-styles;
  padding:1rem;
  margin-left:.5rem;
  cursor:pointer;
  @include breakpoint($tablet){
    padding:1rem 2rem;
    margin-left:1rem;
  }
  background-color:transparent;
  border:2px solid $white;
  @include small-uppercase($white);

  transition:all .3s;
  // @include adjust-font-size-to($font-m);
  &:hover,
  &:focus {
    background-color: $white;
    color:$blue;
  }
}