.widget-social-accounts {

	a {
		margin:0 .25rem;
		&.fa-facebook-f {
			transform:scale(.8);
		}
		&.fa-youtube {
			transform:scale(1.1);
		}
		transition:all .25s;
		&:hover {
			color:$yellow;
		}
	}

	display:flex;
  justify-content:center;


}

.widget-social-item {
    margin:0 .25rem;
		display: inline-flex;
		align-items:center;
		justify-content: center;
		position:relative;
		width:rhythm(2,24px);
		height:rhythm(2,24px);
		border-radius:100%;
		@include fs(24px);
		margin: 0;
    background-color: $white;
		text-align:center;
		text-decoration: none;
    transition: all .3s;
    &:hover,
    &:focus {
      background-color: $blue;
    }

    .fab,
    .far {
			color:$grey;
			line-height:100%;
		}
		margin-right:.5rem;

		&:last-of-type {
			margin-right:0;
		}

		text-shadow:none !important;
		background-image:none !important;
}