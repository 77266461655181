.module-intro {

	.row {
		&.is-flex {
			justify-content:center;
		}
	}

	.social-icons {
		margin-top:rhythm();
  }

  &.is-grey {
    background-color: $grey-light;
  }

  &.is-white {
    background-color: #fff;
  }

}

.intro {
	@include breakpoint($tablet){
		width:span(10);
	}

  @include body-styles;

  h1 {
    color:$blue;
  }

}

.align-left {
  .intro {
    * {
      text-align:left;
    }
  }
}

.align-center {
  .intro {
    * {
      text-align:center;
    }
  }
}

.align-right {
  .intro {
    * {
      text-align:right;
    }
  }
}