.button {
	// font-family:$font-head;
	position:relative;

  @include small-uppercase;
  background-color: $blue;
  color:$white;
  text-decoration: none;
  @include fs(18px);
  padding:1rem 2rem;
  border-radius:2rem;
  margin: .5rem 0;
  display: block;
  @include breakpoint($tablet){
    @include fs($font-m);
    padding:1.5rem 3rem;
    border-radius:3rem;
  }
  @include breakpoint($desktop){
    display: inline-block;
    margin: 0 .5rem;
  }

  transition:all .3s;

	&:hover,
	&:focus {
    background-color: transparentize($color: $blue, $amount: 0.3);
		&::after {

		}
  }

  &.is-grey {
    background-color: $grey;
    &:hover,
    &:focus {
      background-color: transparentize($color: $grey, $amount: 0.3);
    }
  }

}
