.navbar {

	&.is-flex {

    position:relative;
    display:flex;
		justify-content:space-between;

		@include breakpoint($desktop){

		}

	}

	&.is-fixed {
		width:100%;
		position:fixed;
		z-index:500;
		top:0;
	}

}

.navbar-brand {
  width:rem-calc(80px);
  margin:rhythm();
	@include breakpoint($tablet){
		width:rem-calc(120px);
  }
  @include breakpoint($widescreen){
		width:rem-calc(150px);
	}
}

.navbar-logo {
	a {
		position:relative;
		// z-index:501;
		display: block;
		img {
			display: block;
		}
	}
}

.navbar-burger {

	position:relative;
	z-index:502;
	color:$white;
	@include hamburger(60px,3px);

	// transform:translateX(20px);
	// @include breakpoint($tablet){
	// 	transform:translateX(10px);
	// }

}

.navbar-toggle {
	display: flex;
  align-items:center;

  @include breakpoint( $tablet ) {
    margin-right:rhythm();
  }

	@include breakpoint( 1025px ) {
		display: none;
	}
}

.disable-scrolling {
	overflow:hidden;
}

.navbar-menu {

	display: none;

	@include breakpoint(1025px){
		display: block;
		display: flex;
		align-items: stretch;
	}

	.menu,
	.main-navigation {
		display: flex;
		padding: 0;
		list-style-type: none;

	}

	.menu-item {
		margin:0;
		// font-family:$font-condensed;


		a {
			@include small-uppercase();
      color:$white;
			text-decoration: none;
			transition:all .5s;

			&:hover,
			&:focus {
				color:$white;
			}
		}

		&.active,
		&.current-menu-item,
		&.current_page_parent {
			a {
				// color:$yellow;
			}
		}
	}

	.menu-item-depth-0,
	.depth-0 {
		&.active,
		&.current-menu-item,
		&.current_page_parent {
			& > a {
				// color:$yellow;
        position:relative;
				// font-weight:bold;
			}
		}
	}

	.menu-item-depth-0,
	.depth-0 {
		display: flex;
		flex-direction:column;
    align-items:center;
    justify-content: flex-end;
		& > a {
			display: flex;
			align-items:center;
			flex-grow:1;
      padding:rhythm(.75);
      @include breakpoint($widescreen){
        padding:rhythm(.75) rhythm();
      }
		}
	}

	.sub-menu-group {
		position: absolute;
		left:0;
		top:100%;
		width:100%;
		background-color: tint($blue,20%);
		padding:rhythm(2) 0;

		-webkit-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.25);
		-moz-box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.25);
		box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.25);

    .sub-menu {
      display: inline-flex;
      margin:0 auto;
      .menu-item {
        margin:0 rhythm();
        a {
          &:hover,
          &:focus {
            color:$blue-dark;
          }
        }
      }
    }

		.row {
      // padding:0 rhythm(2);
      display: flex;
      justify-content: center;
		}
	}

	.sub-menu {
		margin: 0;
		padding: 0;
		list-style-type: none;
	}

	.depth-1 {
		@include adjust-leading-to(1.5,$font-s);
	}

	.menu-item-has-children {
		.sub-menu-group {
			visibility: hidden;
			opacity:0;
			// TODO: add animation for menu
			transition:visibility 0s linear .3s,opacity .3s linear;
		}
		& > a {
			button {
				cursor:default;
				@include clear-button-styles;
				display: block;
				padding: 0;
				transform:translateX(5px);
				background-color:transparent;
				@include caret(8px,$white);
				// TODO: add animation for caret
				transition:transform .3s;
			}
		}
	}

	@media screen and (prefers-reduced-motion:reduce){
		.navbar-menu .menu-item-has-children > a button {
			transition-duration: 0s;
		}
	}

	.depth-0 {
		& > a:focus,
		& > a:hover {
			color:$white;
			background-color: tint($blue,20%);
		}
	}

	.menu-item-has-children:hover {
		& > a {
			color:$white;
			background-color: tint($blue,20%);
			button {
				transform:translateX(5px) rotate(180deg);
			}
		}
		.sub-menu-group {
			visibility: visible;
			opacity:1;
			transition-delay:0s;
		}
	}

	.menu-item-has-children > a[aria-expanded=true] {
		color:$white;
		background-color: tint($blue,20%);
		button {
			transform:translateX(5px) rotate(180deg);
		}
		& + .sub-menu-group {
			visibility: visible;
			opacity:1;
			transition-delay:0s;
		}
	}

}



.mobile-menu {
	// http://www.greywyvern.com/?post=337
	// animate with visibility hidden
	visibility: hidden;
	opacity:0;
	transition:visibility 0s linear .3s,opacity .3s linear;
	position:fixed;
	width:100%;
	height:100%;
	left:0;
	top:0;
	background-color: transparentize($blue,0.03);
	z-index:500;
	display: flex;
	align-items:center;
	justify-content:center;
	text-align:center;
	overflow:scroll;

	&.is-active {
		visibility: visible;
		opacity:1;
		transition-delay:0s;
    .menu,
    .main-navigation {
			li {
				transform:translateY(0);
				opacity:1;
			}
		}
	}

  .menu,
  .main-navigation {
		padding: 0;
		margin:0;
		text-transform:uppercase;
		font-weight:600;
		letter-spacing:.1rem;
		@include adjust-font-size-to(24px);
		@include breakpoint($tablet){
			@include adjust-font-size-to(36px);
		}
		list-style-type: none;
		line-height:1.2;
		a {
			color:$white;
			display: inline-flex;
			position:relative;
			align-items:center;
			text-decoration: none;
			transition:color .3s;
			&:focus {
				outline:thin dotted;
			}
			&:hover {
				color: tint($blue,50%);
				button:after {
					border-top-color:tint($blue,50%) !important;
				}
			}
		}

		.menu-item {
			margin:1rem 0;
		}

		.sub-menu {
      padding:1px 0;
			.menu-item {
        margin:0;
        padding:.5rem 0;
				letter-spacing:.1rem;
				@include adjust-font-size-to(24px);
				@include breakpoint($tablet){
					@include adjust-font-size-to(36px);
				}
			}
		}

		.menu-item-has-children {
			.sub-menu {
				display: none;
			}
			& > a {

				button {
					position:absolute;
					left:0;
					cursor:pointer;
					@include clear-button-styles;
					background-color: transparent;
					display: block;
					width:rhythm(1.5);
					height:rhythm(1.5);
					padding: 0;
					outline:none;
					transition:all .3s;
					transform:translateX(-125%);
					&:after {
						content:"";
						display: block;
						@include caret(15px,$white);
						position:absolute;
						left:50%;
						top:50%;
						transition:transform .3s;
						transform:translate(-50%,-50%) rotate(-180deg);
					}
				}
			}
			& > a[aria-expanded=true] {
				// color:$grey-dark;
				button {
					transform:translateX(-125%) rotate(180deg);
					&:after {
						// border-top-color:$grey-dark;
					}
				}
				& + .sub-menu {
					// display: block;
				}
			}
		}

		.sub-menu {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		// li.active {
		// 	a {
		// 		color:$yellow;
		// 	}
		// }

		// animate items in
		li {
			opacity:0;
			transform:translateY(-50%);
			transition:all 100ms $easing;

			@for $i from 0 through 8 {
			    $time: $i * 50;
			    &:nth-child(#{$i}){ transition-delay:#{$time}ms; }
			}
		}
	}

}

.navbar {

	.row {
		&.no-flex {
			// put non flexbox fall backs here
		}
	}

}