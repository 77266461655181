.module-columns {

  .article-content {
    @include breakpoint($tablet){
      width:100%;
    }

    p {
      @include size-override();
    }
  }

  .wp-columns {
    @include breakpoint($tablet){
      @include clearfix;
    }
    .column {
      margin-bottom:rhythm();
      @include breakpoint($tablet){
        @include gallery(2 of 4);
        margin-bottom:0;
      }
    }
  }

}