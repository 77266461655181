.artist-list {
	list-style: none;
	margin: 0;
	padding: 0;

	display:grid;
	grid-template-columns:repeat(3,1fr);
	grid-gap:1rem;

	li {
		margin-bottom:1rem;
		&:last-of-type {
			margin-bottom:0;
		}
	}

	a {
		color:$black;
		text-decoration:none !important;

		.img-dummy,
		img {
			display: block;
		}

		span {
			display: block;
		}
		.artist-name {
			margin-top:.5rem;
			text-decoration:underline;
		}
		.artist-details {
			color:$grey;
			font-size:.8em;
			font-weight:normal;
			text-decoration:none !important;
		}
	}

}