figure.module-hero {
  margin: 0;
  padding: 0;

  .hero-bg {
    height:240px;
  }
}
.page-template-alt-page {



}

.content-wrap {

  padding:rhythm(2) 0;

  @include breakpoint($tablet){
    padding:rhythm(4) 0;
    display:flex;
    justify-content:space-between;
  }
  @include breakpoint(1025px){
    width:span(10);
  }

  .module-article,
  .sidebar {
    @include body-styles;
    .button {
      display: block;
      text-align:center;
      @include fs($font-s);
    }
  }

  .article-content {
    @include breakpoint($tablet){
      width:span(6 of 10);
    }
  }

  .sidebar {
    @include breakpoint($tablet){
      width:span(3 of 10);
    }
    p {
      @include size-override();
    }
  }

}