.site-footer {

	&.is-padded {
		padding-bottom:rhythm(1);
		@include breakpoint($tablet){
			padding-bottom:rhythm(2);
		}
	}

	.footer-widgets {
		@include span(10);
		@include pre(1);

		header {
			display: block;
			text-align:center;
			@include fs($font-l);
			@include ld(1.5,$font-l);
			font-family:$font-head;
		}

		.widget {
			margin-bottom:rhythm(3);
			&:last-of-type {
				// margin-bottom:0;
			}
		}

    .menu {
      text-align:center;
      @include breakpoint($tablet){
        display: flex;
        justify-content:center;
      }
      list-style: none;
      margin:0;
      padding:0;
      .menu-item {
        margin:.5rem 1rem;
        @include breakpoint($tablet){
          margin:0 1rem;
        }
        text-transform:uppercase;
        letter-spacing: .2rem;
        a {
          color:$white;
          text-decoration: none;
          &:hover,
          &:focus {
            color:$blue-light;
          }
        }
      }
    }

		.textwidget {
			p {
        @include fs($font-m);
      }
		}

		.widget-logos {
			display:flex;

			justify-content:center;
			flex-wrap:wrap;

			@include breakpoint($tablet){
				flex-wrap: nowrap;
			}

			.widget-logo-item {

				margin:.75rem;
				@include breakpoint($tablet){
					margin:0 gutter(10);
				}
				img {
					height:3rem;
				}
			}

    }



	}

	.credits {
		@include span(10);
		@include pre(1);

		p {
			@include fs($font-s);
			font-family:$font-light;
			text-align:center;
			margin:0;
		}
	}


}