/* Compass vertical rhythm */

$base-font-size: 16px;
$base-line-height: 24px;
$round-to-nearest-half-line: true;
$rhythm-unit: "rem";

@include establish-baseline;

/* Font sizes */

$font-xxs: 	10px;
$font-xs: 	12px;
$font-s: 	14px;
$font-base: $base-font-size;
// $font-m: 	27px;
$font-m: 	24px;
	$font-m-s: 21px;
$font-l: 	36px;
$font-xl: 	60px;
	$font-xl-s: 48px; // just used for manual override at mobile
$font-xxl: 	90px;

/* Font stacks */

// aktiv-grotesk, sans-serif;
// futura-pt, sans-serif


// $font-head : 			futura-pt, sans-serif;
// $font-sans : 			futura-pt, sans-serif;
// $font-condensed : 	$font-sans;
// $font-light : 			$font-sans;
// $font-icon : 			'HPIcons';

$font-head : 			'Texgy Bold', sans-serif;
$font-sans : 			'Texgy', sans-serif;
$font-condensed : 	$font-head;
$font-light : 			$font-sans;
$font-icon : 			'HPIcons';

$font-main: $font-sans;





