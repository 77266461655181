.module-logos {
  padding:rhythm(2) 0;
  @include breakpoint($tablet){
    padding:rhythm(4) 0;
  }

  .is-flex {
    justify-content: center;
  }

  .logos {
    width:span(8);
    @include breakpoint($tablet){
      @include clearfix;
      width:span(10);
      display:flex;
      justify-content:center;
    }

    .logo-item {
      background-color: $white;
      @include breakpoint($tablet){
        width:span(3);
        margin:0 .5rem;
      }
      img {
        display: block;
      }
    }

  }

}